import {
  Box,
  Button,
  Container,
  List,
  ListItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import ComingSoon from "../components/ComingSoon/ComingSoon";
import Header from "../components/Header";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer/Footer";
const BodyText = styled(Typography)(({ theme, list }) => ({
  fontSize: "15px",
  lineHeight: "22px",
  color: theme.palette.text.primary,
  marginBottom: !list && "20px",
}));
const HeadingText = styled(Typography)(({ theme }) => ({
  fontSize: "22px",
  lineHeight: "26px",
  color: theme.palette.text.secondary,
  marginBottom: "20px",
}));

const Advisor = () => {
  return (
    <>
      <Helmet>
        <title>Become an Advisor | Yella</title>
      </Helmet>
      <Header isTransparent />
      <Box backgroundColor='#f6f9fc' mb={"20px"}>
        <Box width='460px' margin={"auto"} py={"80px"} textAlign={"center"}>
          <Typography variant='h5' fontWeight={"600"} color='text.secondary'>
            Earn unlimited income by selling insurance
          </Typography>
          <Button variant='contained' color='primary'>
            Sign Up
          </Button>
        </Box>
      </Box>
      <Container maxWidth='lg'>
        <HeadingText>
          Discover a Rewarding Career in Insurance Sales
        </HeadingText>
        <BodyText>
          When it comes to securing your financial future, exploring lucrative
          career options is essential. While many individuals seek additional
          income sources and work tirelessly to make ends meet, have you
          considered a career in insurance sales as a potential avenue to
          achieve your financial goals?
        </BodyText>
        <BodyText>
          Selling insurance can be an immensely rewarding career choice,
          offering unlimited opportunities to earn a substantial income. By
          embarking on this entrepreneurial journey, you can enjoy a host of
          benefits that will not only improve your financial standing but also
          grant you a sense of independence and fulfilment. Let's delve into the
          various reasons why insurance sales is a favored career path, and how
          you can unlock its incredible earning potential.
        </BodyText>

        <List
          sx={{
            listStyle: "disc",
            ml: "28px",
            mb: "20px",
          }}
        >
          <ListItem
            sx={{
              display: "list-item",
            }}
          >
            <BodyText list mb={1}>
              Unlimited Income Potential
            </BodyText>
            <BodyText>
              In the world of insurance sales, your earnings are only limited by
              your efforts and commitment. As an insurance agent, you have the
              opportunity to earn a generous first-year commission on every
              policy sold. Whether you specialize in life insurance or general
              insurance, the commission you receive on the premium collected is
              a significant portion of your total earnings.
            </BodyText>
          </ListItem>
          <ListItem
            sx={{
              display: "list-item",
            }}
          >
            <BodyText list mb={1}>
              Steady Renewal Commissions
            </BodyText>
            <BodyText>
              In the world of insurance sales, your earnings are only limited by
              your efforts and commitment. As an insurance agent, you have the
              opportunity to earn a generous first-year commission on every
              policy sold. Whether you specialize in life insurance or general
              insurance, the commission you receive on the premium collected is
              a significant portion of your total earnings.In the world of
              insurance sales, your earnings are only limited by your efforts
              and commitment. As an insurance agent, you have the opportunity to
              earn a generous first-year commission on every policy sold.
              Whether you specialize in life insurance or general insurance, the
              commission you receive on the premium collected is a significant
              portion of your total earnings.
            </BodyText>
          </ListItem>
          <ListItem
            sx={{
              display: "list-item",
            }}
          >
            <BodyText list mb={1}>
              Rewards and recognitions
            </BodyText>
            <BodyText>
              In addition to commissions, insurance companies often offer
              exciting rewards and recognition programs for high-performing
              agents. These programs include cash incentives, valuable gifts,
              and prestigious awards. Exceptional performance may even lead to
              attending internationally recognized convocations, where
              top-performing insurance advisors are celebrated for their
              achievements.
            </BodyText>
          </ListItem>
        </List>
        <HeadingText>Commission Structure</HeadingText>
        <BodyText>
          Knowing that you can earn attractive insurance commissions is not
          enough unless you can know the exact figures. After all, the figures
          give a clearer picture, don’t they? So, here is a look at the
          commissions which you can earn by selling different types of insurance
          policies -
        </BodyText>
        <TableContainer
          component={Paper}
          sx={{
            mb: "12px",
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Types of insurance plans</TableCell>
                <TableCell>
                  Applicable commission rates on commissionable premium
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell rowSpan='4'>
                  Motor insurance policies (both car and bike insurance)
                </TableCell>
                <TableCell>
                  Comprehensive insurance policy for private cars - Up to 5% of
                  the premium for own damage (OD) cover
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Comprehensive insurance policy for commercial vehicles: Up to
                  10% of the premium for own damage (OD) cover
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Comprehensive insurance policy for two-wheelers: Up to 6% of
                  the premium for own damage (OD) cover
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Third-party policies for all vehicles: Up to 1.25% of the
                  annual premium
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell rowSpan='2'>Life insurance policies</TableCell>
                <TableCell>
                  Regular premium paying policies: Commissions range from a
                  minimum of 10% to a maximum of 35% based on the policy paying
                  term (PPT).
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Single premium paying policies: Up to 1.5% of the single
                  premium, based on the policy paying term (PPT).
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell rowSpan='2'>Health insurance policies</TableCell>
                <TableCell>
                  Commissions of up to 15% of the annualized premium, depending
                  on the policy paying term (PPT).
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <BodyText list mb={"40px"}>
          Disclaimer : The above list is not exhaustive and the commission rates
          may be amended by the Regulator from time to time. For more
          details/complete particulars, you may visit IRDAI website at &nbsp;
          <a href='www.irdai.gov.in'>www.irdai.gov.in</a>
        </BodyText>
        <BodyText mt={2}>
          Aren’t the rates quite attractive? Can you imagine how much you can
          earn by selling different policies? Here is an illustration for your
          knowledge - <br /> Let’s say you sell four different types of policies
          to four of your contacts. Each policy has a different premium and
          gives you a different commission. Supposing, here’s what you sell to
          your contacts -
        </BodyText>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name of your contact</TableCell>
                <TableCell>Type of policy sold</TableCell>
                <TableCell>Premium amount (assumed)</TableCell>
                <TableCell>
                  Applicable commission rates on commissionable premium
                  (assumed)
                </TableCell>
                <TableCell>Commission Earned</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Mr.A</TableCell>
                <TableCell>Term life insurance</TableCell>
                <TableCell>INR 14,000</TableCell>
                <TableCell>30%</TableCell>
                <TableCell>INR 4,200</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Mr.B</TableCell>
                <TableCell>Health insurance</TableCell>
                <TableCell>INR 12,000</TableCell>
                <TableCell>15%</TableCell>
                <TableCell>INR 1,800</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Mr.C</TableCell>
                <TableCell>Car insurance</TableCell>
                <TableCell>INR 13,000</TableCell>
                <TableCell>15%</TableCell>
                <TableCell>INR 1,950</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Mr.D</TableCell>
                <TableCell>Bike insurance</TableCell>
                <TableCell>INR 2500</TableCell>
                <TableCell>17.5%</TableCell>
                <TableCell>INR 438</TableCell>
              </TableRow>

              <TableRow>
                <TableCell
                  colspan='4'
                  sx={{
                    textAlign: "right",
                  }}
                >
                  <span
                    style={{
                      fontWeight: 500,
                    }}
                  >
                    Total commission earned{" "}
                  </span>
                </TableCell>
                <TableCell>
                  <b>
                    <span
                      style={{
                        fontWeight: 500,
                      }}
                    >
                      INR 8,388
                    </span>
                  </b>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <BodyText list mt={"20px"}>
          Just four policies and you earned a commission of INR 8388, simple,
          wasn’t it?
        </BodyText>
        <BodyText mt={1}>
          Embrace the world of insurance sales, and you'll discover a fulfilling
          career that offers financial growth and personal development. As an
          insurance agent, you not only secure your own future but also play a
          pivotal role in safeguarding the future of your clients. With a
          determined spirit and a commitment to excellence, you can achieve
          success and prosperity while building a respected name within the
          insurance industry. So, why wait? Unlock your potential in insurance
          sales today!
        </BodyText>
      </Container>
      <Footer />
    </>
  );
};

export default Advisor;
